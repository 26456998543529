import React from "react";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";
import { MyImageInput } from "../../components/MyImageInput";
import { MyTextInput } from "../../components/MyTextInput";
import { BooleanInput, required } from "react-admin";

export const SiteSetting: React.FC = (props) => {
  return (
    <>
      <BooleanInput
        label="暗黑模式"
        source="setting.darkMode"
        defaultValue={false}
        validate={required()}
      />
      <BooleanInput
        label="首页是否展示梅西图片"
        source="setting.showMx"
        defaultValue={false}
        validate={required()}
      />
      <MyImageInput label={"网站logo"} source={"setting.logoUrl"} />
      <MyImageInput
        label={"网站logoFavicon"}
        source={"setting.logoFaviconUrl"}
      />
      <MyImageInput label={"网站logoTitle"} source={"setting.logoTitleUrl"} />
      <MyTextInput label="网站名称" source="setting.name" />
      <MyLocalesValueInput label={"网站标题"} source={"setting.title"} />
      <MyLocalesValueInput label={"网站介绍"} source={"setting.description"} />
      <MyImageSwipersValueInput
        label={"首页轮播图(760-214)"}
        source={"setting.indexTopSwiper"}
      />
      <MyLocalesValueInput label={"nft页面标题"} source={"setting.nftTitle"} />
      <MyImageSwipersValueInput
        label={"NFT轮播图(764-284)"}
        source={"setting.nftTopSwiper"}
      />
    </>
  );
};
